<template>
  <div>
    <strong
      >{{ getIncidents() }} {{ $t("role.orders.tour.table.incidentsNr") }}
    </strong>
    <br />
    <strong :class="{ 'text-danger': getIncidentsOpen() > 0 }"
      >{{ getIncidentsOpen() }}
      {{ $t("role.orders.tour.table.incidentsOpenNr") }}</strong
    >
  </div>
</template>

<script>
import get from "lodash/get";

export default {
  name: "OrderRoundIncidents",

  data() {
    return {
      data: {},
    };
  },

  methods: {
    getIncidents() {
      return get(this.data, "incidents", 0);
    },

    getIncidentsOpen() {
      return get(this.data, "incidents_open", 0);
    },
  },
};
</script>
